import React, { useEffect , useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { useCustomContext } from './Provider';


const apiUrl = process.env.REACT_APP_API_URL;


const Home =() =>{
  const {scrollToTop, showAlert , getCookie} = useCustomContext();
  
  const[appointment, setAppointment] = useState({
    name: '',
    email: '',
    mobile: '',
    service_type: '',
    message: '',
  });

  const field_change = (e) =>{
    setAppointment({
      ...appointment, [e.target.name ]:e.target.value
    })
  };

  const appointmentSubmit = (e) => {
    e.preventDefault();

    const trimmedAppointment = {
      name: appointment.name.trim(),
      email: appointment.email.trim(),
      mobile: appointment.mobile.trim(),
      service_type: appointment.service_type.trim(),
      message: appointment.message.trim(),
    };

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const phonePattern = /^(08|07|09)\d{9}$/;

    if (!trimmedAppointment.name || !trimmedAppointment.email || !trimmedAppointment.mobile || !trimmedAppointment.service_type || !trimmedAppointment.message) {
        showAlert('danger', 'Error!', 'All fields are required.');
        return;
    }

    if (!emailPattern.test(trimmedAppointment.email)) {
        showAlert('danger', 'Error!', 'Please enter a valid email address.');
        return;
    }

    if (!phonePattern.test(trimmedAppointment.mobile)) {
      showAlert('danger', 'Error!', 'Please enter a valid phone number. It must start with 07, 08, or 09 and be 11 digits long.');
      return;
    }

    fetch(`${apiUrl}/create-appointment/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': getCookie('csrftoken'),
        },
        body: JSON.stringify(trimmedAppointment),
    })
    .then(response => {
        if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
        }
        return response.json();
    })
    .then(data => {
        if (data.message === 'Appointment created successfully') {
            showAlert('success', 'Success!', 'Appointment successfully booked!');
            setAppointment({
                name: '',
                email: '',
                mobile: '',
                service_type: '',
                message: '',
            });
            scrollToTop();
        } else {
          showAlert('danger', 'Error!', data.message);
        }
    })
    .catch(error => {
        showAlert('danger', 'Error!', 'Failed to submit appointment.');
    });
};

  useEffect(() => {
    const carouselElement = document.getElementById('header-carousel');
    if (carouselElement) {
      new window.bootstrap.Carousel(carouselElement, {
        interval: 3000,
        ride: 'carousel'
      });
    }

    window.$(".testimonial-carousel").owlCarousel({
      autoplay: true,
      smartSpeed: 1000,
      items: 1,
      dots: false,
      loop: true,
      nav: true,
      navText: [
        '<i class="bi bi-chevron-left"></i>',
        '<i class="bi bi-chevron-right"></i>'
      ]
    });
  }, []);
  return(<>
        {/*Header Start*/}
        {/* Carousel Start */}
        <div className="container-fluid p-0 mb-4 wow fadeIn" data-wow-delay="0.1s">
          <div
            id="header-carousel"
            className="carousel slide"
            data-bs-ride="carousel"
            data-bs-interval="3000"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img className="w-100" src="/static/img/header_1.jpg" alt="Header Slide" />
                <div className="carousel-caption">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-12 col-lg-10 header_content">
                        <h5 className="text-light text-uppercase mb-3 animated slideInDown">
                          Welcome to{" "}
                          <span className="text-info">Jahdoes Limited</span>
                        </h5>
                        <h1 className="display-2 text-light mb-3 animated slideInDown">
                          Your Trusted Building and Civil Engineering Contractor
                        </h1>
                        <ol className="breadcrumb mb-4 pb-2">
                          <li className="breadcrumb-item fs-5 text-light">
                            Commercial
                          </li>
                          <li className="breadcrumb-item fs-5 text-light">
                            Residential
                          </li>
                          <li className="breadcrumb-item fs-5 text-light">
                            Industrial
                          </li>
                        </ol>
                        <a href="#get_started" className="btn btn-info py-3 px-5">
                          Get Started
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <img className="w-100" src="/static/img/header_2.jpg" alt="Header Slide" />
                <div className="carousel-caption">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-12 col-lg-10 header_content">
                        <h5 className="text-light text-uppercase mb-3 animated slideInDown">
                          Welcome to{" "}
                          <span className="text-info">Jahdoes Limited</span>
                        </h5>
                        <h1 className="display-2 text-light mb-3 animated slideInDown">
                          Where Every Project Becomes a Signature of Excellence!
                        </h1>
                        <ol className="breadcrumb mb-4 pb-2">
                          <li className="breadcrumb-item fs-5 text-light">
                            Commercial
                          </li>
                          <li className="breadcrumb-item fs-5 text-light">
                            Residential
                          </li>
                          <li className="breadcrumb-item fs-5 text-light">
                            Industrial
                          </li>
                        </ol>
                        <a href="#get_started" className="btn btn-info py-3 px-5">
                          Get Started
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <img className="w-100" src="/static/img/header_3.jpg" alt="Header Slide" />
                <div className="carousel-caption">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-12 col-lg-10 header_content">
                        <h5 className="text-light text-uppercase mb-3 animated slideInDown">
                          Welcome to{" "}
                          <span className="text-info">Jahdoes Limited</span>
                        </h5>
                        <h1 className="display-2 text-light mb-3 animated slideInDown">
                          Where Vision Meets Precision!
                        </h1>
                        <ol className="breadcrumb mb-4 pb-2">
                          <li className="breadcrumb-item fs-5 text-light">
                            Commercial
                          </li>
                          <li className="breadcrumb-item fs-5 text-light">
                            Residential
                          </li>
                          <li className="breadcrumb-item fs-5 text-light">
                            Industrial
                          </li>
                        </ol>
                        <a href="#get_started" className="btn btn-info py-3 px-5">
                          Get Started
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#header-carousel"
              data-bs-slide="prev"
            >
              <span className="carousel-control-prev-icon" aria-hidden="true" />
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#header-carousel"
              data-bs-slide="next"
            >
              <span className="carousel-control-next-icon" aria-hidden="true" />
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        {/* Carousel End */}
        {/*Header End*/}
        {/*Who we are starts*/}
        <div className="container-xxl py-4">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                <div
                  className="position-relative overflow-hidden ps-5 pt-5 h-100"
                  style={{ minHeight: 400 }}
                >
                  <img
                    className="position-absolute w-100 h-100"
                    src="/static/img/who_we_are.jpg"
                    alt="who we are"
                    style={{ objectFit: "cover" }}
                  />
                  <div
                    className="position-absolute top-0 start-0 bg-white pe-3 pb-3"
                    style={{ width: 200, height: 200 }}
                  >
                    <div className="d-flex flex-column justify-content-center text-center bg-info h-100 p-3">
                      <h1 className="text-white">15+</h1>
                      <h2 className="text-white">Years</h2>
                      <h5 className="text-white mb-0">Experience</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                <div className="h-100">
                  <div className="border-start border-4 border-info ps-4 mb-5">
                    <h6 className="text-body text-uppercase mb-2 section-title">
                      Who We Are
                    </h6>
                    <h1 className="display-6 mb-0">
                      Innovative Building Solutions for Homes and Industries
                    </h1>
                  </div>
                  <p>
                    At <span className="text-info" style={{fontWeight:500}}>Jahdoes Limited</span>, we
                    transform imagination into stunning reality. With a passion for
                    excellence and a flair for innovation, we build extraordinary
                    spaces that captivate and inspire. Our expert team blends
                    visionary design with superior craftsmanship, ensuring each
                    project becomes a landmark of distinction and creativity.
                  </p>
                  <p className="mb-4">
                    We deliver tailored solutions for both residential and industrial
                    projects with meticulous attention to detail and a commitment to
                    quality. From concept to completion, our transparent and
                    collaborative process guarantees client satisfaction. Choose{" "}
                    <span className="text-info" style={{fontWeight:500}}>Jahdoes Limited</span> for a
                    partnership grounded in trust, innovation, and excellence.
                  </p>
                  <div className="border-top mt-4 pt-4">
                    <div className="row g-4">
                      <div
                        className="col-sm-4 d-flex wow fadeIn"
                        data-wow-delay="0.1s"
                      >
                        <i className="fa fa-check fa-2x text-info flex-shrink-0 me-3" />
                        <h6 className="mb-0">Timely Project Completion</h6>
                      </div>
                      <div
                        className="col-sm-4 d-flex wow fadeIn"
                        data-wow-delay="0.3s"
                      >
                        <i className="fa fa-check fa-2x text-info flex-shrink-0 me-3" />
                        <h6 className="mb-0">Round-the-Clock Availability</h6>
                      </div>
                      <div
                        className="col-sm-4 d-flex wow fadeIn"
                        data-wow-delay="0.5s"
                      >
                        <i className="fa fa-check fa-2x text-info flex-shrink-0 me-3" />
                        <h6 className="mb-0">Certified and Trusted Experts</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*Who we are end*/}
        {/* Facts Start */}
        <div className="container-fluid my-5 p-0">
          <div className="row g-0">
          <div className="col-xl-3 col-sm-6 wow fadeIn" data-wow-delay="0.1s">
              <div className="position-relative">
                <img className="img-fluid w-100" src="/static/img/fact-2.jpg" alt="" />
                <div className="facts-overlay">
                  <h1 className="display-1">01</h1>
                  <h4 className="text-white mb-3">Architecture</h4>
                  <p className="text-white">
                    Designing innovative and sustainable spaces that blend
                    functionality with aesthetic appeal, shaping skylines.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 wow fadeIn" data-wow-delay="0.3s">
              <div className="position-relative">
                <img className="img-fluid w-100" src="/static/img/fact-1.jpg" alt="" />
                <div className="facts-overlay">
                  <h1 className="display-1">02</h1>
                  <h4 className="text-white mb-3">Construction</h4>
                  <p className="text-white">
                    Building structures with precision and expertise, delivering
                    quality projects on time and within budget.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 wow fadeIn" data-wow-delay="0.5s">
              <div className="position-relative">
                <img className="img-fluid w-100" src="/static/img/fact-4.jpg" alt="" />
                <div className="facts-overlay">
                  <h1 className="display-1">03</h1>
                  <h4 className="text-white mb-3">Mechanical</h4>
                  <p className="text-white">
                    Providing top-notch mechanical solutions, ensuring efficiency and
                    reliability in every project we undertake.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 wow fadeIn" data-wow-delay="0.7s">
              <div className="position-relative">
                <img className="img-fluid w-100" src="/static/img/fact-3.jpg" alt="" />
                <div className="facts-overlay">
                  <h1 className="display-1">04</h1>
                  <h4 className="text-white mb-3">Interior Design</h4>
                  <p className="text-white">
                    Crafting beautiful and functional interiors, creating spaces that
                    reflect your style and enhance your lifestyle.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Facts End */}
        {/*Why Choose Us Start*/}
        <div className="container-xxl py-5">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="border-start border-5 border-info ps-4 mb-5">
                  <h6 className="text-body text-uppercase mb-2 section-title">
                    Why Choose Us!
                  </h6>
                  <h1 className="display-6 mb-0">
                    Why <span className="text-info" style={{fontWeight:500}}>Jahdoes Limited</span> is the
                    Perfect Choice
                  </h1>
                </div>
                <p className="mb-5">
                  At <span className="text-info">Jahdoes Limited</span>, we stand out
                  as your ideal partner for building and construction projects. Our
                  dedication to quality, innovation, and client satisfaction sets us
                  apart. Here's why you should choose us:
                </p>
                <div className="row gy-5 gx-4">
                  <div className="col-sm-6 wow fadeIn" data-wow-delay="0.1s">
                    <div className="d-flex align-items-center mb-3">
                      <i className="fa fa-check fa-2x text-info flex-shrink-0 me-3" />
                      <h6 className="mb-0">Unmatched Expertise</h6>
                    </div>
                    <span>
                      Our team of skilled professionals brings extensive experience
                      and knowledge to every project, ensuring exceptional results.
                    </span>
                  </div>
                  <div className="col-sm-6 wow fadeIn" data-wow-delay="0.2s">
                    <div className="d-flex align-items-center mb-3">
                      <i className="fa fa-check fa-2x text-info flex-shrink-0 me-3" />
                      <h6 className="mb-0">Innovative Solutions</h6>
                    </div>
                    <span>
                      We leverage the latest technologies and creative approaches to
                      deliver cutting-edge designs and efficient solutions.
                    </span>
                  </div>
                  <div className="col-sm-6 wow fadeIn" data-wow-delay="0.3s">
                    <div className="d-flex align-items-center mb-3">
                      <i className="fa fa-check fa-2x text-info flex-shrink-0 me-3" />
                      <h6 className="mb-0">Commitment to Excellence</h6>
                    </div>
                    <span>
                      We prioritize quality in every aspect, from materials to
                      craftsmanship, ensuring durability and precision.
                    </span>
                  </div>
                  <div className="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                    <div className="d-flex align-items-center mb-3">
                      <i className="fa fa-check fa-2x text-info flex-shrink-0 me-3" />
                      <h6 className="mb-0">Client-Centric Approach</h6>
                    </div>
                    <span>
                      Your vision drives us. We work closely with you to understand
                      your needs and exceed your expectations.
                    </span>
                  </div>
                  <div className="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                    <div className="d-flex align-items-center mb-3">
                      <i className="fa fa-check fa-2x text-info flex-shrink-0 me-3" />
                      <h6 className="mb-0">Timely Delivery</h6>
                    </div>
                    <span>
                      We adhere to strict timelines, ensuring your project is
                      completed on schedule and within budget.
                    </span>
                  </div>
                  <div className="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                    <div className="d-flex align-items-center mb-3">
                      <i className="fa fa-check fa-2x text-info flex-shrink-0 me-3" />
                      <h6 className="mb-0">Sustainable Practices</h6>
                    </div>
                    <span>
                      We integrate eco-friendly methods and materials to promote
                      sustainability and environmental responsibility.
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                <div
                  className="position-relative overflow-hidden ps-5 pt-5 h-100"
                  style={{ minHeight: 400 }}
                >
                  <img
                    className="position-absolute w-100 h-100"
                    src="/static/img/why_choose_us.jpg"
                    alt="Why Choose Us"
                    style={{ objectFit: "cover" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Why Choose Us End */}
        {/* Service Start */}
        <div className="container-xxl py-5">
          <div className="container">
            <div className="row g-5 align-items-end mb-5">
              <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="border-start border-5 border-info ps-4">
                  <h6 className="text-body text-uppercase mb-2 section-title">
                    Our Top Services
                  </h6>
                  <h1 className="display-6 mb-0">
                    Construction And Renovation Solutions
                  </h1>
                </div>
              </div>
              <div
                className="col-lg-6 text-lg-end wow fadeInUp"
                data-wow-delay="0.3s"
              >
                <Link className="btn btn-info py-3 px-5" to="/services">
                  More Details
                </Link>
              </div>
            </div>
            <div className="row g-4 justify-content-center">
              <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="service-item bg-light overflow-hidden h-100">
                  <img className="img-fluid" src="/static/img/home_service1.jpg" alt="" />
                  <div className="service-text position-relative text-center h-100 p-4">
                    <h5 className="mb-3">Residential Construction</h5>
                    <p>
                      Crafting dream homes with exceptional quality, personalized
                      design, and meticulous attention to detail.
                    </p>
                    <HashLink smooth to="/services#res_ser" className="small">
                      READ MORE
                      <i className="fa fa-arrow-right ms-3" />
                    </HashLink>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <div className="service-item bg-light overflow-hidden h-100">
                  <img className="img-fluid" src="/static/img/home_service2.jpg" alt="" />
                  <div className="service-text position-relative text-center h-100 p-4">
                    <h5 className="mb-3">Commercial Construction</h5>
                    <p>
                      Building dynamic, efficient spaces tailored for business success
                      and operational excellence.
                    </p>
                    <HashLink smooth to="/services#com_ser" className="small">
                      READ MORE
                      <i className="fa fa-arrow-right ms-3" />
                    </HashLink>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                <div className="service-item bg-light overflow-hidden h-100">
                  <img className="img-fluid" src="/static/img/home_service3.jpg" alt="" />
                  <div className="service-text position-relative text-center h-100 p-4">
                    <h5 className="mb-3">Renovation and Remodeling</h5>
                    <p>
                      Revamping spaces with precision and creativity to match your
                      vision and lifestyle needs.
                    </p>
                    <HashLink smooth to="/services#ren_rev_ser" className="small">
                      READ MORE
                      <i className="fa fa-arrow-right ms-3" />
                    </HashLink>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="service-item bg-light overflow-hidden h-100">
                  <img className="img-fluid" src="/static/img/home_service4.jpg" alt="" />
                  <div className="service-text position-relative text-center h-100 p-4">
                    <h5 className="mb-3">Interior Design and Fit-Out</h5>
                    <p>
                      Creating stylish, functional interiors with bespoke design and
                      high-quality finishing touches.
                    </p>
                    <HashLink smooth to="/services#int_des_ser" className="small">
                      READ MORE
                      <i className="fa fa-arrow-right ms-3" />
                    </HashLink>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <div className="service-item bg-light overflow-hidden h-100">
                  <img className="img-fluid" src="/static/img/home_service5.jpg" alt="" />
                  <div className="service-text position-relative text-center h-100 p-4">
                    <h5 className="mb-3">Exterior Works</h5>
                    <p>
                      Transforming outdoor spaces with expert craftsmanship, from
                      landscaping to durable, beautiful finishes.
                    </p>
                    <HashLink smooth to="services#ext_ser" className="small">
                      READ MORE
                      <i className="fa fa-arrow-right ms-3" />
                    </HashLink>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                <div className="service-item bg-light overflow-hidden h-100">
                  <img className="img-fluid" src="/static/img/home_service6.jpg" alt="" />
                  <div className="service-text position-relative text-center h-100 p-4">
                    <h5 className="mb-3">Project Management</h5>
                    <p>
                      Ensuring timely, cost-effective, and quality project completion
                      with expert oversight and control.
                    </p>
                    <HashLink smooth to="/services#pro_ser" className="small">
                      READ MORE
                      <i className="fa fa-arrow-right ms-3" />
                    </HashLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Service End */}
        {/* Appointment Start */}
        <div
          id="get_started"
          className="container-fluid appointment my-4 py-4 wow fadeIn"
          data-wow-delay="0.1s"
        >
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-5 col-md-6 wow fadeIn" data-wow-delay="0.3s">
                <div className="border-start border-5 border-info ps-4 mb-5">
                  <h6 className="text-white text-uppercase mb-2 section-title">
                    Appointment
                  </h6>
                  <h1 className="display-6 text-white mb-0">
                    A Company Focused on Crafting Superior Service and Maintenance
                    Solutions
                  </h1>
                </div>
                <p className="text-white mb-0">
                  Need expert help or want to discuss your project? Book an
                  appointment with us today to experience exceptional service and
                  tailored solutions. Our team is here to ensure your needs are met
                  with excellence.
                </p>
              </div>
              <div className="col-lg-7 col-md-6 wow fadeIn" data-wow-delay="0.5s">
                <form onSubmit={appointmentSubmit}>
                  <div className="row g-3">
                    <div className="col-sm-6">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control bg-dark border-0 text-light"
                          id="name"
                          name="name"
                          placeholder="Your Name"
                          value={appointment.name}
                          onChange={field_change}
                        />
                        <label htmlFor="name" className="text-light">
                          Your Name
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-floating">
                        <input
                          type="email"
                          className="form-control bg-dark border-0 text-light"
                          id="email"
                          name="email"
                          placeholder="Your Email"
                          value={appointment.email}
                          onChange={field_change}
                        />
                        <label htmlFor="email" className="text-light">
                          Your Email
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-floating">
                        <input
                          type="number"
                          className="form-control bg-dark border-0 text-light"
                          id="number"
                          name="mobile"
                          placeholder="Phone Number"
                          value={appointment.mobile}
                          onChange={field_change}
                        />
                        <label htmlFor="number" className="text-light">
                          Phone Number
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control bg-dark border-0 text-light"
                          id="s_type"
                          placeholder="Service Tyoe"
                          name="service_type"
                          value={appointment.service_type}
                          onChange={field_change}
                        />
                        <label htmlFor="s_type" className="text-light">
                          Service Type
                        </label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <textarea
                          className="form-control bg-dark border-0 text-light"
                          placeholder="Leave a message here"
                          id="message"
                          name="message"
                          style={{ height: 100 }}
                          value={appointment.message}
                          onChange={field_change}
                        />
                        <label htmlFor="message" className="text-light">
                          Message
                        </label>
                      </div>
                    </div>
                    <div className="col-12">
                      <button className="btn btn-info w-100 py-3" type="submit">
                        Get Appointment
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* Appointment End */}
        {/* Testimonial Start */}
        <div className="container-xxl py-5">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="border-start border-5 border-info ps-4 mb-5">
                  <h6 className="text-body text-uppercase mb-2 section-title">
                    Testimonial
                  </h6>
                  <h1 className="display-6 mb-0">What Our Happy Clients Say!</h1>
                </div>
                <p className="mb-4">
                  Creating extraordinary spaces with excellence. Our clients' stories
                  reflect the innovation, dedication, and craftsmanship we bring to
                  every project.
                </p>
                <div className="row g-4">
                  <div className="col-sm-6">
                    <div className="d-flex align-items-center mb-2">
                      <i className="fa fa-users fa-2x text-info flex-shrink-0" />
                      <h1 className="ms-3 mb-0">43+</h1>
                    </div>
                    <h5 className="mb-0">Happy Clients</h5>
                  </div>
                  <div className="col-sm-6">
                    <div className="d-flex align-items-center mb-2">
                      <i className="fa fa-check fa-2x text-info flex-shrink-0" />
                      <h1 className="ms-3 mb-0">18+</h1>
                    </div>
                    <h5 className="mb-0">Projects Done</h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 wow fadeInUp" data-wow-delay="0.5s">
                <div className="owl-carousel testimonial-carousel">
                  <div className="testimonial-item">
                    <img
                      className="img-fluid mb-4"
                      src="/static/img/test_3.jpg"
                      alt=""
                    />
                    <p className="fs-5">Their team was efficient, skilled, and communicative throughout our entire project.</p>
                    <div className="bg-info mb-3" style={{ width: 60, height: 5 }} />
                    <h5>Adeboye</h5>
                  </div>
                  <div className="testimonial-item">
                    <img
                      className="img-fluid mb-4"
                      src="/static/img/test_10.jpg"
                      alt=""
                    />
                    <p className="fs-5">Jahdoes Limited made our dream home a reality with their expert construction and design services.</p>
                    <div className="bg-info mb-3" style={{ width: 60, height: 5 }} />
                    <h5>Nkechi</h5>
                  </div>
                  <div className="testimonial-item">
                    <img
                      className="img-fluid mb-4"
                      src="/static/img/test_5.jpg"
                      alt=""
                    />
                    <p className="fs-5">The level of expertise and care Jahdoes provided is simply exceptional.</p>
                    <div className="bg-info mb-3" style={{ width: 60, height: 5 }} />
                    <h5>Adewale</h5>
                  </div>
                  <div className="testimonial-item">
                    <img
                      className="img-fluid mb-4"
                      src="/static/img/test_9.jpg"
                      alt=""
                    />
                    <p className="fs-5">Professional, reliable, and committed to delivering excellence. Jahdoes is our go-to construction partner.</p>
                    <div className="bg-info mb-3" style={{ width: 60, height: 5 }} />
                    <h5>Stephanie</h5>
                  </div>
                  <div className="testimonial-item">
                    <img
                      className="img-fluid mb-4"
                      src="/static/img/test_4.jpg"
                      alt="testimonial Img"
                    />
                    <p className="fs-5">We couldn’t be happier with the quality and service from Jahdoes Limited.</p>
                    <div className="bg-info mb-3" style={{ width: 60, height: 5 }} />
                    <h5>Rufus</h5>
                  </div>
                  <div className="testimonial-item">
                    <img
                      className="img-fluid mb-4"
                      src="/static/img/test_6.jpg"
                      alt=""
                    />
                    <p className="fs-5">A seamless experience from planning to execution. Jahdoes Limited is the best in the business.</p>
                    <div className="bg-info mb-3" style={{ width: 60, height: 5 }} />
                    <h5>Joseph</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Testimonial End */}
      </>
  )
}


export default Home