import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();
  
  useEffect(() => {
    const scrollToTop = () => {
      if (document.readyState === 'complete') {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      } else {
        setTimeout(scrollToTop, 100);
      }
    };

    scrollToTop();
  }, [pathname]);

  return null;
};

export default ScrollToTop;