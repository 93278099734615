import React from "react";
import { Link } from "react-router-dom";

const Projects =() => {
    return(
            <>
    {/* Page Header Start */}
    <div
        className="container-fluid project-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
    >
        <div className="container py-5">
        <h1 className="display-1 text-white animated slideInDown">Projects</h1>
        <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb text-uppercase mb-0">
            <li className="breadcrumb-item">
                <Link className="text-white text-decoration-none" to="/">
                Home
                </Link>
            </li>
            <li className="breadcrumb-item text-info active" aria-current="page">
                Projects
            </li>
            </ol>
        </nav>
        </div>
    </div>
    {/* Page Header End */}
    {/* Project Start */}
    <div className="container-xxl project py-5">
        <div className="container">
        <div
            className="text-center mx-auto mb-5 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: 800 }}
        >
            <h4 className="section-title">Our Projects</h4>
            <h1 className="display-5 mb-4">
            Explore Some of Our Latest and Ongoing Projects
            </h1>
        </div>
        <div
            className="accordion mx-auto wow fadeInUp"
            data-wow-delay="0.4s"
            id="accordionExample"
            style={{ maxWidth: 700 }}
        >
            <div className="accordion-item">
            <h2 className="accordion-header">
                <button
                className="accordion-button text-secondary custom_accordion"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
                >
                Building Maintenance at Ketu, Lagos State.
                </button>
            </h2>
            <div
                id="collapseOne"
                className="accordion-collapse collapse show"
                data-bs-parent="#accordionExample"
            >
                <div className="accordion-body">
                <img className="img-fluid" src="/static/img/Picture1.png" alt="" />
                </div>
            </div>
            </div>
            <div className="accordion-item">
            <h2 className="accordion-header">
                <button
                className="accordion-button text-secondary custom_accordion collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
                >
                Development at Lekki, Lagos State.
                </button>
            </h2>
            <div
                id="collapseTwo"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
            >
                <div className="accordion-body">
                <img className="img-fluid" src="/static/img/Picture2.png" alt="" />
                </div>
            </div>
            </div>
            <div className="accordion-item">
            <h2 className="accordion-header">
                <button
                className="accordion-button text-secondary custom_accordion collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
                >
                Building Maintenanca at Allen Avenue, Ikeja, Lagos State
                </button>
            </h2>
            <div
                id="collapseThree"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
            >
                <div className="accordion-body">
                <img className="img-fluid" src="/static/img/Picture3.png" alt="" />
                </div>
            </div>
            </div>
            <div className="accordion-item">
            <h2 className="accordion-header">
                <button
                className="accordion-button text-secondary custom_accordion collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
                >
                Building Maintenanca at Lekki, Lagos State
                </button>
            </h2>
            <div
                id="collapseFour"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
            >
                <div className="accordion-body">
                <img className="img-fluid" src="/static/img/Picture4.png" alt="" />
                </div>
            </div>
            </div>
            <div className="accordion-item">
            <h2 className="accordion-header">
                <button
                className="accordion-button text-secondary custom_accordion collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive"
                >
                Ongoing Development at Agbaoku Street, Opebi, Lagos State.
                </button>
            </h2>
            <div
                id="collapseFive"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
            >
                <div className="accordion-body">
                <img className="img-fluid" src="/static/img/Picture5.png" alt="" />
                </div>
            </div>
            </div>
        </div>
        </div>
    </div>
    {/* Project End */}
    </>

    )
}

export default Projects