import React, { useState } from "react";
import { Link} from "react-router-dom";
import { useCustomContext } from './Provider';

const apiUrl = process.env.REACT_APP_API_URL;


const Footer =() =>{
    const {scrollToTop , showAlert , getCookie } = useCustomContext();

    const [subscriber, setSubscriber] = useState('');
      
    const handleSubscriberChange = (e) => {
        setSubscriber(e.target.value);
    };
    
    const handleSubmitSubscriber = (e) => {
        e.preventDefault();
        const trimmedSubscriberEmail = subscriber.trim();
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!emailPattern.test(trimmedSubscriberEmail)) {
            showAlert('danger', 'Error!', 'Please enter a valid email address.');
            return;
        }

        fetch(`${apiUrl}/create-subscriber/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken'),
            },
            body: JSON.stringify({ email: subscriber })
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            }
            return response.json();
        })
        .then(data => {
            if (data.message === 'Success') {
                showAlert('success', 'Success!', 'Subscription successful!');
                setSubscriber('');
                scrollToTop();
            } else {
                showAlert('danger', 'Error!', data.message);
            }
        })
        .catch(error => {
            showAlert('danger', 'Error!', error);
        });
    };

    return(
        <>
        {/* Footer Start */}
        <div
            className="container-fluid bg-dark footer mt-4 pt-4 wow fadeIn"
            data-wow-delay="0.1s">
            <div className="container py-4">
            <div className="row g-5">
                <div className="col-lg-3 col-md-6">
                <div className="d-flex align-items-center mb-3 justify-content-center justify-content-md-start">
                    <img
                    src="/static/img/logo_jahdoes.png"
                    alt="Logo"
                    className="me-3"
                    style={{ height: 50 }}
                    />
                    <h1
                    className="text-info mb-0 text-center text-md-start"
                    style={{ fontSize: "1.5em" }}
                    >
                    <span className="d-inline-block text-info">JAHDOES</span>
                    <span className="d-inline-block text-info">LIMITED</span>
                    </h1>
                </div>
                <p>
                    Discover the Magic of Construction with Jahdoes Limited - Building
                    Tomorrow's Dreams Today with Innovation, Excellence, and
                    Craftsmanship!
                </p>
                </div>
                <div className="col-lg-3 col-md-6">
                <h4 className="text-info mb-2">Address</h4>
                <div className="d-flex align-items-center mb-2">
                    <i className="fa fa-map-marker-alt me-3" />
                    <p className="mb-0">
                    85, ALLEN AVENUE,
                    <br /> IKEJA, LAGOS STATE,
                    <br /> NIGERIA.
                    </p>
                </div>
                <div className="d-flex align-items-center mb-2">
                    <i className="fa fa-phone-alt me-3" />
                    <p className="mb-0">
                    +234-806-1222-160
                    <br />
                    +234-704-0556-417
                    </p>
                </div>
                <Link to="mailto:jahdoeslimited@gmail.com" className="d-block btn btn-link" style={{fontSize:13}}><i className="fa fa-envelope me-1" />jahdoeslimited@gmail.com</Link>
                <Link to="mailto:support@jahdoeslimited.com" className="d-block btn btn-link" style={{fontSize:13}}><i className="fa fa-envelope me-1" />support@jahdoeslimited.com</Link>
                </div>
                <div className="col-lg-3 col-md-6">
                <h4 className="text-info mb-2">Quick Links</h4>
                <Link className="btn btn-link" to="/">
                    Home
                </Link>
                <Link className="btn btn-link" to="/about">
                    About Us
                </Link>
                <Link className="btn btn-link" to="/services">
                    Our Services
                </Link>
                <Link className="btn btn-link" to="/projects">
                    Projects
                </Link>
                <Link className="btn btn-link" to="/contact">
                    Contact Us
                </Link>
                <Link className="btn btn-link" to="/privacy">
                    Privacy Policy
                </Link>
                </div>
                <div className="col-lg-3 col-md-6">
                <h4 className="text-info mb-2">Newsletter</h4>
                <p>
                    Stay updated with the latest news and insights from Jahdoes Limited.
                    Sign up for our newsletter!
                </p>
                <div className="d-grid">
                    <form onSubmit={handleSubmitSubscriber}>
                    <input className="form-control bg-transparent text-light w-100 py-3 ps-4 pe-5 mb-2" type="email" placeholder="example@email.com" name="email" value={subscriber} onChange={handleSubscriberChange}/>
                    <button type="submit" className="btn btn-info py-2">
                    Subscribe
                    </button>
                    </form>
                </div>
                </div>
            </div>
            </div>
            <div className="container-fluid copyright">
            <div className="container">
                <div className="row company_footer">
                <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                    <Link to="/">Jahdoes Limited</Link> ©2024., All Right Reserved.
                </div>
                <div className="col-md-6 text-center text-md-end">
                Made with <span role="img" aria-label="blue heart">💙</span> by{" "}
                <Link href="https://opeoluwaakinuliola.dev" target="_blank">
                    Micheal Opeoluwa Akinuliola</Link>
                </div>
                </div>
            </div>
            </div>
        </div>
        {/* Footer End */}
    </>

    )
}

export default Footer