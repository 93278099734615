import React from "react";
import { Link } from "react-router-dom";

const About =() =>{
    return(
        <>
        {/* Page Header Start */}
        <div
            className="container-fluid page-header py-5 mb-5 wow fadeIn"
            data-wow-delay="0.1s"
        >
            <div className="container py-5">
            <h1 className="display-1 text-white animated slideInDown">About Us</h1>
            <nav aria-label="breadcrumb animated slideInDown">
                <ol className="breadcrumb text-uppercase mb-0">
                <li className="breadcrumb-item">
                    <Link className="text-white text-decoration-none" to="/">
                    Home
                    </Link>
                </li>
                <li className="breadcrumb-item text-info active" aria-current="page">
                    About
                </li>
                </ol>
            </nav>
            </div>
        </div>
        {/* Page Header End */}
        {/* About Start */}
        <div className="container-xxl py-5">
            <div className="container">
            <div className="row g-5">
                <div className="col-lg-6 wow fadeIn" data-wow-delay="0.3s">
                <div className="about-img">
                    <img
                    className="img-fluid"
                    src="/static/img/about_header2.jpg"
                    alt="About Header"
                    />
                    <img className="img-fluid" src="/static/img/abt_head1.jpg" alt="About Header" />
                </div>
                </div>
                <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                <h4 className="section-title">About Us</h4>
                <h1 className="display-5 mb-4">
                    Building Dreams, One Project at a Time
                </h1>
                <p>
                <span className="text-info text-uppercase" style={{fontWeight:500}}>Jahdoes Limited</span>, a leading construction company in Lagos, Nigeria,
                    excels in estate development, water supply systems, and building
                    projects. With a skilled team and modern machinery, we deliver
                    high-quality, timely real estate solutions.
                </p>
                <p className="mb-4">
                    Strategic partnerships and extensive expertise enable us to handle
                    complex projects effectively. Our mission is to provide innovative,
                    value-added construction services that meet clients' unique needs
                    and exceed expectations.
                </p>
                <div className="d-flex align-items-center mb-1 wow fadeInUp" data-wow-delay="0.7s">
                    <div
                    className="d-flex flex-shrink-0 align-items-center justify-content-center border border-5 border-info"
                    style={{ width: 120, height: 120 }}
                    >
                    <h1 className="display-1 mb-n2" data-toggle="counter-up">
                        15
                    </h1>
                    </div>
                    <div className="ps-4">
                    <h3>Years</h3>
                    <h3>Working</h3>
                    <h3 className="mb-0">Experience</h3>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        {/* About End */}
        {/* Why partner with us Start */}
        <div className="container-xxl py-5">
            <div className="container">
            <div className="row g-5">
                <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                <h4 className="section-title">Why Choose Us!</h4>
                <h1 className="display-5 mb-4">
                    Why You Should Trust Us? Learn More About Us!
                </h1>
                <p className="mb-4">
                    At <span className="text-info text-uppercase" style={{fontWeight:500}}>Jahdoes Limited</span>, we blend innovation and expertise to deliver
                    exceptional construction services in Lagos, Nigeria. Our commitment
                    to quality, timely completion, and client satisfaction sets us apart
                    in the industry.
                </p>
                <div className="row g-4">
                    <div className="col-12 wow fadeInUp" data-wow-delay="0.3s">
                    <div className="d-flex align-items-start abt_facts">
                        <img
                        className="flex-shrink-0"
                        src="/static/img/service_icon1.png"
                        alt="Icon"
                        />
                        <div className="ms-4">
                        <h3>Innovation and Expertise</h3>
                        <p className="mb-0">
                            At <span className="text-info text-uppercase" style={{fontWeight:500}}>Jahdoes Limited</span>, we provide innovative construction
                            solutions, leveraging advanced technology and expert
                            knowledge for exceptional quality.
                        </p>
                        </div>
                    </div>
                    </div>
                    <div className="col-12 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="d-flex align-items-start abt_facts">
                        <img
                        className="flex-shrink-0"
                        src="/static/img/back-in-time.png"
                        alt="Icon"
                        />
                        <div className="ms-4">
                        <h3>Timely Completion</h3>
                        <p className="mb-0">
                            We ensure timely project completion, meeting deadlines
                            without compromising on quality and excellence.
                        </p>
                        </div>
                    </div>
                    </div>
                    <div className="col-12 wow fadeInUp" data-wow-delay="0.7s">
                    <div className="d-flex align-items-start abt_facts">
                        <img className="flex-shrink-0" src="/static/img/rating.png" alt="Icon" />
                        <div className="ms-4">
                        <h3>Client Satisfaction</h3>
                        <p className="mb-0">
                            Our priority is client satisfaction, delivering exceptional
                            results and personalized service that exceed expectations.
                        </p>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                <div className="feature-img">
                    <img className="img-fluid" src="/static/img/abt_head3.jpg" alt="About header" />
                    <img className="img-fluid" src="/static/img/abt_head4.jpg" alt="About header" />
                </div>
                </div>
            </div>
            </div>
        </div>
        {/* Why Partner with us End */}
        {/* Team Start */}
        <div className="container-xxl py-4">
            <div className="container">
            <div
                className="text-center mx-auto mb-5 wow fadeInUp"
                data-wow-delay="0.1s"
                style={{ maxWidth: 600 }}
            >
                <h4 className="section-title">Team Members</h4>
                <h1 className="display-5 mb-4">
                Meet Our Experts: Crafting Excellence and Precision
                </h1>
            </div>
            <div className="row g-0 team-items justify-content-evenly">
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="team-item position-relative">
                    <div className="position-relative">
                    <img className="img-fluid" src="/static/img/mr_ike.png" alt="Md/Ceo" />
                    </div>
                    <div className="bg-light text-center p-4">
                    <h3 className="mt-2">Mr. Ike, Israel Emeka</h3>
                    <span className="text-info" style={{fontSize:20,fontWeight:500}}>Managing Director/CEO</span>
                    </div>
                </div>
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <div className="team-item position-relative">
                    <div className="position-relative">
                    <img className="img-fluid" src="/static/img/mrs_ike.png" alt="Company Secretary" />
                    </div>
                    <div className="bg-light text-center p-4">
                    <h3 className="mt-2">Mrs. Ike, Becky Ijeoma</h3>
                    <span className="text-info" style={{fontSize:20,fontWeight:500}}>Company Secretary</span>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        {/* Team End */}
        </>
    )
}

export default About