import React , {useState , useEffect}  from 'react';

const Alert = ({ type, message, showAlert, onClose }) => {
    const [visible, setVisible] = useState(showAlert);

    useEffect(() => {
        setVisible(showAlert); 

        if (showAlert) {
            const timer = setTimeout(() => {
                onClose();
            }, 10000);

            return () => clearTimeout(timer);
        }
    }, [showAlert, onClose]);

    if (!visible) return null; 
    return (
        <div
            className={`alert alert-${type} alert-dismissible fade ${showAlert ? 'show' : ''} custom-alert`}
            role="alert"
            style={{ display: showAlert ? 'block' : 'none' }}
        >
            <strong>{message.title}</strong> {message.body}
            <button
                type="button"
                className="btn-close"
                onClick={() => {
                    onClose();
                    setVisible(false);
                }} 
                data-bs-dismiss="alert"
                aria-label="Close"
            ></button>
        </div>
    );
};

export default Alert;