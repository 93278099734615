import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Whatsapp = () => {
  const [isAtBottom, setIsAtBottom] = useState(false);

  const handleScroll = () => {
    const atBottom = (window.innerHeight + window.scrollY) >= document.body.offsetHeight;
    setIsAtBottom(atBottom);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Link
      className={`whatsapp ${isAtBottom ? "whatsapp-at-bottom" : ""}`}
      to=""
    >
      <img src="/static/img/whatsapp_logo.png" alt="whatsapp" width="50%" />
    </Link>
  );
};

export default Whatsapp;
