import React, { createContext, useContext, useState, useEffect } from 'react';

const CustomContext = createContext();

export const CustomProvider = ({ children }) => {
    const [alert, setAlert] = useState({
        type: '',
        message: { title: '', body: '' },
        showAlert: false,
    });

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const getCookie = (name) => {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    };

    const showAlert = (type, title, body) => {
        setAlert({
            type,
            message: { title, body },
            showAlert: true,
        });
    };

    const hideAlert = () => {
        setAlert(prevState => ({
            ...prevState,
            showAlert: false,
        }));
    };

    return (
        <CustomContext.Provider value={{ scrollToTop, getCookie, showAlert, hideAlert, alert }}>
            {children}
        </CustomContext.Provider>
    );
};

export const useCustomContext = () => {
  return useContext(CustomContext);
};