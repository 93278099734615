import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useCustomContext } from './Provider';

const Services =() =>{
    const {scrollToTop} = useCustomContext();
    const { hash } = useLocation();

    useEffect(() => {
        scrollToTop()

        window.$('.services-testimonial-carousel').owlCarousel({
            autoplay: true,
            smartSpeed: 1000,
            loop: true,
            nav: false,
            dots: true,
            items: 1,
            dotsData: true,
        });

        if (hash) {
            const element = document.getElementById(hash.substring(1));
            if (element) {
              element.scrollIntoView({ behavior: 'smooth' });
            }
          }
    }, [hash, scrollToTop]); 
    
    return(
        <>
        {/* Page Header Start */}
        <div
            className="container-fluid service-header py-5 mb-5 wow fadeIn"
            data-wow-delay="0.1s"
        >
            <div className="container py-5">
            <h1 className="display-1 text-white animated slideInDown">Services</h1>
            <nav aria-label="breadcrumb animated slideInDown">
                <ol className="breadcrumb text-uppercase mb-0">
                <li className="breadcrumb-item">
                    <Link className="text-white text-decoration-none" to="/">
                    Home
                    </Link>
                </li>
                <li className="breadcrumb-item text-info active" aria-current="page">
                    Services
                </li>
                </ol>
            </nav>
            </div>
        </div>
        {/* Page Header End */}
        {/* Facts Start */}
        <div className="container-xxl py-5">
            <div className="container pt-4">
            <div className="row g-4">
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="fact-item text-center bg-light h-100 p-5 pt-0">
                    <div className="fact-icon">
                    <img src="/static/img/service_icon1.png" alt="Icon" />
                    </div>
                    <h3 className="mb-3">Innovation and Expertise</h3>
                    <p className="mb-0">
                    At <span className="text-info" style={{fontWeight:500}}>Jahdoes Limited</span>, we provide innovative construction solutions,
                    leveraging advanced technology and expert knowledge for
                    exceptional quality.
                    </p>
                </div>
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <div className="fact-item text-center bg-light h-100 p-5 pt-0">
                    <div className="fact-icon">
                    <img src="/static/img/back-in-time.png" alt="Icon" />
                    </div>
                    <h3 className="mb-3">Timely Completion</h3>
                    <p className="mb-0">
                    We ensure timely project completion, meeting deadlines without
                    compromising on quality and excellence.
                    </p>
                </div>
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                <div className="fact-item text-center bg-light h-100 p-5 pt-0">
                    <div className="fact-icon">
                    <img src="/static/img/rating.png" alt="Icon" />
                    </div>
                    <h3 className="mb-3">Client Satisfaction</h3>
                    <p className="mb-0">
                    Our priority is client satisfaction, delivering exceptional
                    results and personalized service that exceed expectations.
                    </p>
                </div>
                </div>
            </div>
            </div>
        </div>
        {/* Facts End */}
        {/* Service Start */}
        <div className="container-xxl py-4">
            <div className="container">
            <div
                className="text-center mx-auto mb-5 wow fadeInUp"
                data-wow-delay="0.1s"
                style={{ maxWidth: 800 }}
            >
                <h4 className="section-title">Our Services</h4>
                <h1 className="display-5 mb-4">
                Comprehensive Construction and Renovation Solutions for Residential
                and Commercial Projects
                </h1>
            </div>
            <div className="row g-4">
                <div id="res_ser" className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="service-items d-flex position-relative text-center h-100">
                    <img className="bg-img" src="/static/img/services_1.jpg" alt="" />
                    <div className="service-texts p-5">
                    <img className="mb-4" src="/static/img/construction.png" alt="Icon" />
                    <h3 className="mb-3">Residential Construction</h3>
                    <p className="mb-3">Our services ensure quality and reliability in every project we undertake.</p>
                    <ul className="text-start">
                        <li>Custom Home Building</li>
                        <li>Single-family Homes</li>
                        <li>Multi-family Residences</li>
                        <li>Townhouses and Condominiums</li>
                        <li>Home Extensions and Additions</li>
                    </ul>
                    </div>
                </div>
                </div>
                <div id="com_ser" className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <div className="service-items d-flex position-relative text-center h-100">
                    <img className="bg-img" src="/static/img/services_2.jpg" alt="" />
                    <div className="service-texts p-5">
                    <img className="mb-4" src="/static/img/apartment.png" alt="Icon" />
                    <h3 className="mb-3">Commercial Construction</h3>
                    <p className="mb-3">Expert solutions for all your commercial construction needs.</p>
                    <ul className="text-start">
                        <li>Office Buildings</li>
                        <li>Retail Spaces</li>
                        <li>Restaurants and Cafés</li>
                        <li>Warehouses and Industrial Facilities</li>
                        <li>Healthcare Facilities and Clinics</li>
                    </ul>
                    </div>
                </div>
                </div>
                <div id="ren_rev_ser" className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                <div className="service-items d-flex position-relative text-center h-100">
                    <img className="bg-img" src="/static/img/services_3.jpg" alt="" />
                    <div className="service-texts p-5">
                    <img className="mb-4" src="/static/img/renovation.png" alt="Icon" />
                    <h3 className="mb-3">Renovation and Remodeling</h3>
                    <p className="mb-3">Transforming spaces with expert renovation and remodeling services.</p>
                    <ul className="text-start">
                        <li>Kitchen Renovations</li>
                        <li>Bathroom Remodeling</li>
                        <li>Basement Finishing</li>
                        <li>Attic Conversions</li>
                        <li>Whole-House Renovations</li>
                    </ul>
                    </div>
                </div>
                </div>
                <div id="int_des_ser" className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="service-items d-flex position-relative text-center h-100">
                    <img className="bg-img" src="/static/img/services_4.jpg" alt="" />
                    <div className="service-texts p-5">
                    <img className="mb-4" src="/static/img/interior-design.png" alt="Icon" />
                    <h3 className="mb-3">Interior Design and Fit-Out</h3>
                    <p className="mb-3">Creating stunning interiors with custom design and precise fit-out solutions.</p>
                    <ul className="text-start">
                        <li>Space Planning</li>
                        <li>Interior Decoration</li>
                        <li>Furniture and Fixtures</li>
                        <li>Lighting Design</li>
                        <li>Custom Cabinetry and Millwork</li>
                    </ul>
                    </div>
                </div>
                </div>
                <div id="ext_ser" className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <div className="service-items d-flex position-relative text-center h-100">
                    <img className="bg-img" src="/static/img/services_5.jpg" alt="" />
                    <div className="service-texts p-5">
                    <img className="mb-4" src="/static/img/wall.png" alt="Icon" />
                    <h3 className="mb-3">Exterior Works</h3>
                    <p className="mb-3">Enhancing outdoor spaces with durable and aesthetically pleasing exterior solutions.</p>
                    <ul className="text-start">
                        <li>Landscaping and Hardscaping</li>
                        <li>Decks and Patios</li>
                        <li>Roofing and Siding</li>
                        <li>Windows and Doors Replacement</li>
                        <li>Exterior Painting</li>
                        <li>Borehole Drilling Services</li>
                    </ul>
                    </div>
                </div>
                </div>
                <div id="pro_ser" className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                <div className="service-items d-flex position-relative text-center h-100">
                    <img className="bg-img" src="/static/img/services_6.jpg" alt="" />
                    <div className="service-texts p-5">
                    <img className="mb-4" src="/static/img/project-management.png" alt="Icon" />
                    <h3 className="mb-3">Project Management</h3>
                    <p className="mb-3">Efficiently overseeing projects from conception to completion, ensuring timely delivery and quality results.</p>
                    <ul className="text-start">
                        <li>Feasibility Studies</li>
                        <li>Budgeting and Cost Estimation</li>
                        <li>Scheduling and Timeline Management</li>
                        <li>Quality Control and Assurance</li>
                        <li>Permits and Regulatory Compliance</li>
                    </ul>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        {/* Service End */}
        {/* Testimonial Start */}
        <div className="container-xxl py-5">
            <div className="container">
            <div
                className="text-center mx-auto mb-5 wow fadeInUp"
                data-wow-delay="0.1s"
                style={{ maxWidth: 800 }}
            >
                <h4 className="section-title">Testimonial</h4>
                <h1 className="display-5 mb-4">Hear From Those Who Trust Us and Our Services</h1>
            </div>
            <div
                className="owl-carousel services-testimonial-carousel wow fadeInUp"
                data-wow-delay="0.1s"
            >
                <div
                className="testimonial-item text-center"
                data-dot="<img class='img-fluid' src='/static/img/test_2.jpg' alt=''>"
                >
                <p className="fs-5">Jahdoes Limited exceeded our expectations with their top-notch craftsmanship and timely project completion.</p>
                <h3>David</h3>
                </div>
                <div
                className="testimonial-item text-center"
                data-dot="<img class='img-fluid' src='/static/img/test_7.jpg' alt=''>"
                >
                <p className="fs-5">Their attention to detail and professionalism is unmatched. Highly recommend Jahdoes for any construction project.</p>
                <h3>Cassandra</h3>
                </div>
                <div
                className="testimonial-item text-center"
                data-dot="<img class='img-fluid' src='/static/img/test_1.jpg' alt=''>"
                >
                <p className="fs-5">From start to finish, Jahdoes Limited delivered quality and precision in every aspect of our build.</p>
                <h3>Jeffery</h3>
                </div>
                <div
                className="testimonial-item text-center"
                data-dot="<img class='img-fluid' src='/static/img/test_8.jpg' alt=''>"
                >
                <p className="fs-5">Excellent service and outstanding results. Jahdoes Limited truly brought our vision to life.</p>
                <h3>Halimatu</h3>
                </div>
            </div>
            </div>
        </div>
        {/* Testimonial End */}
        </>
    )
}

export default Services