import React, { useEffect, useState }  from "react";

const BackToTop =() =>{
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
        setIsVisible(true);
        } else {
        setIsVisible(false);
        }
    };

    const scrollToTop = () => {
      const duration = 1500; // Duration in milliseconds
      const start = window.pageYOffset;
      const startTime = performance.now();
  
      const easeInOutQuad = (t) => (t < 0.5 ? 2 * t * t : 1 - Math.pow(-2 * t + 2, 2) / 2);
  
      const scroll = (currentTime) => {
        const timeElapsed = currentTime - startTime;
        const progress = Math.min(timeElapsed / duration, 1);
        const easeProgress = easeInOutQuad(progress);
        window.scrollTo(0, start - start * easeProgress);
  
        if (timeElapsed < duration) {
          requestAnimationFrame(scroll);
        }
      };
  
      requestAnimationFrame(scroll);
    };
  
    useEffect(() => {
      window.addEventListener("scroll", toggleVisibility);
      return () => {
        window.removeEventListener("scroll", toggleVisibility);
      };
    }, []);
    return(
        <>
        {isVisible && (
        <button
          onClick={scrollToTop}
          className="btn btn-lg btn-info btn-lg-square back-to-top"
        >
          <i className="bi bi-arrow-up" />
        </button>
      )}
        </>
    )
}

export default BackToTop