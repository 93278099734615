import React, { useEffect } from 'react';

const Spinner = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      const spinnerElement = document.getElementById('spinner');
      if (spinnerElement) {
        spinnerElement.classList.remove('show');
      }
    }, 1); // Adjust the timeout as needed

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, []);

  return (
    <div
      id="spinner"
      className="show position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
    >
      <div
        className="spinner-border position-relative text-info"
        style={{ width: '7rem', height: '7rem' }}
        role="status"
      ></div>
      <img
        className="position-absolute top-50 start-50 translate-middle spinner_img"
        src="/static/img/logo_jahdoes.png" // Ensure the path is correct
        width="8%"
        style={{ borderRadius: '50%', border: 'none' }}
        alt="Icon"
      />
    </div>
  );
};

export default Spinner;