import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useCustomContext } from './Provider';
import Nav from './Nav';
import Alert from './Alert'
import Home from './Home';
import About from './About';
import Services from './Services';
import Projects from './Projects';
import Contact from './Contact';
import Privacy from './Privacy';
import Footer from './Footer';
import Whatsapp from './Whatsapp'
import BackToTop from './BackToTop'
import ScrollToTop from './ScrollToTop';
import Spinner from './Spinner';
import ModalNav from './ModalNav';


const App = () => {
  const { alert , hideAlert } = useCustomContext();
  return (
    <Router>
      <ScrollToTop />
      <Spinner/>
      <Nav />
      <Alert type={alert.type} message={alert.message} showAlert={alert.showAlert} onClose={hideAlert} />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
      <Footer/>
      <Whatsapp/>
      <BackToTop/>
      <ModalNav/>
    </Router>
  );
}


export default App;