import React from "react";
import { Link , useLocation } from "react-router-dom";

const ModalNav =() =>{
    const location = useLocation();
    const currentPath = location.pathname;

    const isActive = (path) => currentPath === path ? 'nav-active' : '';
    const handleLinkClick = (event) => {
        event.preventDefault();
        const modalElement = document.getElementById('exampleModal');
        if (modalElement) {
            window.$(modalElement).modal('hide');
        }
        setTimeout(() => {
            window.scrollTo(0, 0); // Scroll to top
            const link = event.target.getAttribute('href');
            window.location.href = link;
        }, 300); // Adjust the delay as needed
    };
    return (
        <>
        {/*Nav Modal */}
        <div
            className="modal fade"
            id="exampleModal"
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            align="center"
        >
            <div
            className="modal-dialog modal-dialog-centered"
            align="center"
            style={{ width: "65%" }}
            >
            <div className="modal-content">
                <div className="modal-body custom_dialog">
                <button type="button"
                    className="btn-close mobile-button-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"></button>
                {/* <button
                    
                /> */}
                <ul className="navbar-nav">
                    <li className="mobile-nav-item">
                    <Link className={`nav-link ${isActive('/')}`} aria-current="page" to="/" onClick={handleLinkClick}>
                        Home
                    </Link>
                    </li>
                    <li className="mobile-nav-item">
                    <Link className={`nav-link ${isActive('/about')}`} to="/about" onClick={handleLinkClick}>
                        About Us
                    </Link>
                    </li>
                    <li className="mobile-nav-item">
                    <Link className={`nav-link ${isActive('/services')}`} to="/services" onClick={handleLinkClick}>
                        Our Services
                    </Link>
                    </li>
                    <li className="mobile-nav-item">
                    <Link className={`nav-link ${isActive('/projects')}`} to="/projects" onClick={handleLinkClick}>
                        Projects
                    </Link>
                    </li>
                    <li className="mobile-nav-item">
                    <Link className={`nav-link ${isActive('/contact')}`} to="/contact" onClick={handleLinkClick}>
                        Contact Us
                    </Link>
                    </li>
                </ul>
                </div>
            </div>
            </div>
        </div>
        </>
    )
}

export default ModalNav