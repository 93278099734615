import React from "react";
import { Link , useLocation } from 'react-router-dom';


const Nav= () => {
    const location = useLocation();
    const currentPath = location.pathname;

    const isActive = (path) => currentPath === path ? 'nav-active' : '';


    return (
            <nav className="navbar navbar-expand-lg bg-nav fixed-top">
            <div className="container d-flex justify-content-between align-items-center">
            <Link className="navbar-brand d-flex align-items-center text-info" to="/">
                <img
                src="/static/img/logo_jahdoes.png"
                alt="jahdoes limited logo"
                className="me-2"
                />
                <span>JAHDOES LIMITED</span>
            </Link>
            <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
            >
                <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse">
                <ul className="navbar-nav ms-auto my-2 my-lg-0">
                <li className="nav-item">
                    <Link
                    className={`nav-link ${isActive('/')}`} aria-current="page" to="/">
                    Home
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className={`nav-link ${isActive('/about')}`} to="/about">
                    About Us
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className={`nav-link ${isActive('/services')}`} to="/services">
                    Our Services
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className={`nav-link ${isActive('/projects')}`} to="/projects">
                    Projects
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className={`nav-link ${isActive('/contact')}`} to="/contact">
                    Contact Us
                    </Link>
                </li>
                </ul>
            </div>
            </div>
        </nav>)
}


export default Nav