import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useCustomContext } from './Provider';

const apiUrl = process.env.REACT_APP_API_URL;

const Contact =() =>{
    const {scrollToTop, showAlert , getCookie } = useCustomContext();

    const[contact, setContact] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
      });
    
      const field_change = (e) =>{
        setContact({
          ...contact, [e.target.name]:e.target.value
        })
      };

      const contactSubmit=(e)=>{
        e.preventDefault();

        const trimmedContact = {
            name: contact.name.trim(),
            email: contact.email.trim(),
            subject: contact.subject.trim(),
            message: contact.message.trim(),
        };

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!trimmedContact.name || !trimmedContact.email || !trimmedContact.subject || !trimmedContact.message) {
            showAlert('danger', 'Error!', 'All fields are required.');
            return;
        }

        if (!emailPattern.test(trimmedContact.email)) {
            showAlert('danger', 'Error!', 'Please enter a valid email address.');
            return;
        }

        fetch(`${apiUrl}/contact/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie('csrftoken'), 
        },
        body: JSON.stringify(trimmedContact),
        })
        .then(response => {
            if (!response.ok) {
                return response.json().then(errorData => {
                    throw new Error(errorData.message || 'An error occurred');
                });
            }
            return response.json();
        })
        .then(data => {
        if (data.message === 'Contact submitted successfully') {
            showAlert('success', 'Success!', 'Contact submitted successfully!');
            setContact({
                name: '',
                email: '',
                subject: '',
                message: '',
            });
            scrollToTop();
        } else {
            showAlert('danger', 'Error!', data.message);
        }
        })
        .catch(error => {
            showAlert('danger', 'Error!', error);
        });
    };

    return (
        <>
            {/* Page Header Start */}
            <div
                className="container-fluid contact-header py-5 mb-5 wow fadeIn"
                data-wow-delay="0.1s">
                <div className="container py-5">
                <h1 className="display-1 text-white animated slideInDown">Contact Us</h1>
                <nav aria-label="breadcrumb animated slideInDown">
                    <ol className="breadcrumb text-uppercase mb-0">
                    <li className="breadcrumb-item">
                        <Link className="text-white text-decoration-none" to="/">
                        Home
                        </Link>
                    </li>
                    <li className="breadcrumb-item text-info active" aria-current="page">
                        Contact us
                    </li>
                    </ol>
                </nav>
                </div>
            </div>
            {/* Page Header End */}
            {/* Contact Start */}
            <div className="container-xxl py-4">
                <div className="container">
                <div
                    className="text-center mx-auto mb-5 wow fadeInUp"
                    data-wow-delay="0.1s"
                    style={{ maxWidth: 600 }}
                >
                    <h4 className="section-title">Contact Us</h4>
                    <h1 className="display-5 mb-4">
                    If You Have Any Query, Please Feel Free Contact Us
                    </h1>
                </div>
                <div className="row g-5 align-items-center">
                    <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="d-flex flex-column justify-content-between h-100">
                        <div className="bg-light d-flex align-items-center w-100 p-4 mb-4">
                        <div
                            className="d-flex flex-shrink-0 align-items-center justify-content-center bg-dark"
                            style={{ width: 55, height: 55 }}
                        >
                            <i className="fa fa-map-marker-alt text-info" />
                        </div>
                        <div className="ms-4">
                            <p className="mb-2">Address</p>
                            <h3 className="mb-0 contact-us">
                            85, Allen Avenue, Ikeja, Lagos State, Nigeria.
                            </h3>
                        </div>
                        </div>
                        <div className="bg-light d-flex align-items-center w-100 p-4 mb-4">
                        <div
                            className="d-flex flex-shrink-0 align-items-center justify-content-center bg-dark"
                            style={{ width: 55, height: 55 }}
                        >
                            <i className="fa fa-phone-alt text-info" />
                        </div>
                        <div className="ms-4">
                            <p className="mb-2">Call Us Now</p>
                            <h3 className="mb-0 contact-us">+234-806-1222-160</h3>
                            <h3 className="mb-0 contact-us">+234-704-0556-417</h3>
                        </div>
                        </div>
                        <div className="bg-light d-flex align-items-center w-100 p-4">
                        <div
                            className="d-flex flex-shrink-0 align-items-center justify-content-center bg-dark"
                            style={{ width: 55, height: 55 }}
                        >
                            <i className="fa fa-envelope-open text-info" />
                        </div>
                        <div className="ms-4">
                            <p className="mb-2">Mail Us Now</p>
                        <Link to="mailto:jahdoeslimited@gmail.com" className="contact-mail">jahdoeslimited@gmail.com</Link>
                        <Link to="mailto:support@jahdoeslimited.com" className="contact-mail">support@jahdoeslimited.com</Link>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                    <p className="mb-4">
                        Have any questions or need assistance? Feel free to reach out to us!
                        We're here to help and look forward to connecting with you.
                    </p>
                    <form onSubmit={contactSubmit}>
                        <div className="row g-3">
                        <div className="col-md-6">
                            <div className="form-floating">
                            <input
                                type="text"
                                className="form-control"
                                id="name"
                                name="name"
                                value={contact.name}
                                onChange={field_change}
                                placeholder="Your Name"
                            />
                            <label htmlFor="name">Your Name</label>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-floating">
                            <input
                                type="email"
                                className="form-control"
                                id="email"
                                name="email"
                                value={contact.email}
                                onChange={field_change}
                                placeholder="Your Email"
                            />
                            <label htmlFor="email">Your Email</label>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-floating">
                            <input
                                type="text"
                                className="form-control"
                                id="subject"
                                name="subject"
                                value={contact.subject}
                                onChange={field_change}
                                placeholder="Subject"
                            />
                            <label htmlFor="subject">Subject</label>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-floating">
                            <textarea
                                className="form-control"
                                placeholder="Leave a message here"
                                id="message"
                                style={{ height: 100 }}
                                name="message"
                                value={contact.message}
                                onChange={field_change}
                            />
                            <label htmlFor="message">Message</label>
                            </div>
                        </div>
                        <div className="col-12">
                            <button className="btn btn-info w-100 py-3" type="submit">
                            Send Message
                            </button>
                        </div>
                        </div>
                    </form>
                    </div>
                </div>
                </div>
            </div>
            {/* Contact End */}
            {/* Google Map Start */}
            <div className="container-xxl pt-5 px-0 wow fadeIn" data-wow-delay="0.1s">
                <iframe
                title="Google Maps"
                className="w-100 mb-n2"
                style={{ height: 450 }}
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3963.3469208900374!2d3.348025674993656!3d6.603739493390159!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b923191a518ed%3A0x82b57920d9566f6d!2s85%20Allen%20Ave%2C%20Allen%2C%20Ikeja%20101233%2C%20Lagos!5e0!3m2!1sen!2sng!4v1722918992950!5m2!1sen!2sng"
                frameBorder={0}
                allowFullScreen=""
                aria-hidden="false"
                tabIndex={0}
                />
            </div>
            {/* Google Map End */}
        </>
    )
}

export default Contact