import React from "react";
import { Link } from "react-router-dom";

const Privacy =() =>{
    return (
        <>
        {/* Page Header Start */}
        <div
            className="container-fluid policy-header py-5 mb-5 wow fadeIn"
            data-wow-delay="0.1s">
            <div className="container py-5">
            <h1 className="display-1 text-white animated slideInDown">
                Privacy Policy
            </h1>
            <nav aria-label="breadcrumb animated slideInDown">
                <ol className="breadcrumb text-uppercase mb-0">
                <li className="breadcrumb-item">
                    <Link className="text-white text-decoration-none" to="/">
                    Home
                    </Link>
                </li>
                <li className="breadcrumb-item text-info active" aria-current="page">
                    Privacy Policy
                </li>
                </ol>
            </nav>
            </div>
        </div>
        {/* Page Header End */}
        {/* Privacy Policy Start */}
        <div className="container mt-5">
            <section className="policy-section">
            <h1>Privacy Policy</h1>
            <h2>1. Introduction</h2>
            <p>
                Welcome to our Privacy Policy. Your privacy is critically important to
                us. This Privacy Policy explains how we collect, use, and protect your
                information when you use our website.
            </p>
            <h2>2. Information We Collect</h2>
            <ul>
                <li>
                <strong>Personal Information:</strong> We collect personal information
                that you voluntarily provide when booking appointments, filling out
                contact forms, or subscribing to our newsletter.
                </li>
                <li>
                <strong>Usage Data:</strong> We collect information about your
                interactions with our website, including IP addresses, browser types,
                and pages visited.
                </li>
            </ul>
            <h2>3. How We Use Your Information</h2>
            <p>We use the information we collect to:</p>
            <ul>
                <li>Provide and manage your appointments and inquiries.</li>
                <li>Send periodic newsletters if you have subscribed.</li>
                <li>
                Improve our website and services based on user feedback and usage
                data.
                </li>
            </ul>
            <h2>4. User Interactions</h2>
            <p>
                Our website does not support user account creation. By using our site,
                you agree to the following:
            </p>
            <ul>
                <li>
                <strong>Appointments:</strong> When booking appointments, you are
                responsible for providing accurate information. We may use your
                details to confirm your booking and provide relevant updates.
                </li>
                <li>
                <strong>Contact Forms:</strong> Any information submitted through our
                contact forms will be used solely to respond to your inquiries. We are
                not liable for any unauthorized use of your information.
                </li>
                <li>
                <strong>Newsletter Subscriptions:</strong> By subscribing to our
                newsletter, you agree to receive periodic updates. You can unsubscribe
                at any time using the link provided in our emails.
                </li>
            </ul>
            <h2>5. Data Security</h2>
            <p>
                We implement a variety of security measures to maintain the safety of
                your personal information. However, no method of transmission over the
                internet or electronic storage is completely secure.
            </p>
            <h2>6. Cookies</h2>
            <p>
                Our website uses cookies to enhance user experience. You can set your
                browser to refuse cookies or to alert you when cookies are being sent.
                However, if you do so, some parts of our site may not function properly.
            </p>
            <h2>7. Changes to This Policy</h2>
            <p>
                We may update this Privacy Policy from time to time. We will notify you
                of any changes by posting the new Privacy Policy on this page. You are
                advised to review this Privacy Policy periodically for any changes.
            </p>
            <h2>8. Contact Us</h2>
            <p>
                If you have any questions or concerns about this Privacy Policy, please
                contact us using the information provided on our website.
            </p>
            </section>
        </div>
        {/* Privacy Policy Ends */}
    </>
    )
}

export default Privacy